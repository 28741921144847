<template>
  <v-text-field
    ref="inputRef"
    type="text"
    outlined
    single-line
    :disabled="disabled"
    :hide-details="hideDetails"
  />
</template>

<script>
  import { useCurrencyInput } from 'vue-currency-input'
  import { watch } from '@vue/composition-api'
  export default {
    name: 'CurrencyInput',
    props: {
      // eslint-disable-next-line vue/require-default-prop
      value: Number, // Vue 2: value
      // eslint-disable-next-line vue/require-default-prop
      options: {
        type: Object,
        default: () => ({ currency: 'usd', locale: 'en-US', currencyDisplay: 'hidden', precision: true, hideGroupingSeparatorOnFocus: false }),
      },
      space: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: [Boolean, String],
        default: false,
      },
      hideDetails: {
        type: [Boolean, String],
        default: false,
      },
    },
    setup (props) {
      // const isFloat = (number) => {
      //   console.log('checking if it float or not 😆', number)
      //   return String(number).search('.') > 0
      // }

      // const findDecimmal = (number) => {
      //   return String(number).split('.')[1]
      // }

      // if (isFloat(props.value)) {
      //   console.log('isFloat 😁')
      //   if (findDecimmal(props.value).length > 2) {
      //     props.options = { ...props.options, currency: 'BHD' }
      //   }
      // }

      if (props.space) {
        props.options = { ...props.options, currency: 'BHD' }
      }

      const { inputRef, setValue } = useCurrencyInput(props.options)

      watch(
        () => props.value, // Vue 2: props.value
        (value) => {
          console.log('value in watch', value)
          setValue(value)
        }
      )

      //   watch(
      //     () => props.options,
      //     (options) => {
      //       setOptions(options)
      //     }
      //   )
      return { inputRef }
    },
  }
</script>
